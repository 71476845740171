<template>
  <v-container>
    <v-card class="pt-3 pb-3">
      <v-card-title>
        <v-row>
          <v-col cols="12" md="10" class="mt-6">
            {{ formTitle }}
          </v-col>

          <v-col cols="12" md="2" class="mt-6">
            <v-btn small depressed color="primary">
              <router-link to="/questions" class="d-flex text-decoration-none" style="color: #fff !important">
                {{ btnTitle }}
              </router-link>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-textarea
            v-model="form.title"
            :prepend-inner-icon="icons.mdiAccountOutline"
            label="Title"
            :rules="[v => !!v || 'Title is required']"
            outlined
            dense
            required
          ></v-textarea>
          <v-text-field
            v-model="form.linkText"
            :prepend-inner-icon="icons.mdiFormatText"
            label="Link Text"
            placeholder="Pyelonephritis (Kidney Infection)"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="form.link"
            :prepend-inner-icon="icons.mdiLink"
            label="Link"
            placeholder="https://google.com"
            outlined
            dense
          ></v-text-field>
          <v-select
            v-model="form.moduleId"
            :items="modules"
            item-value="id"
            item-text="name"
            :rules="[v => !!v || 'Module is required']"
            label="Module"
            outlined
            dense
            required
            @change="getModuleQuestions"
          >
          </v-select>
          <v-row>
            <v-col md="6" sm="12" class="mt-3">
              <v-checkbox v-model="form.hideAnswer" label="Endpoint?" class="mt-n2" outlined dense></v-checkbox>
            </v-col>
            <v-col sm="12" md="6">
              <v-btn v-if="form.moduleId" color="primary" center @click="addParent">
                Add Parent
              </v-btn>
              <!-- <v-checkbox v-model="form.hasParent" label="Has Parent" class="mt-n2" outlined dense></v-checkbox> -->
            </v-col>
          </v-row>
          <div v-if="form.hasParent">
            <v-card v-for="(item, key) in form.parent" :key="key" class="mb-4" elevation="0" outlined>
              <v-card-title>
                <v-btn text icon color="error" right absolute @click="removeParent(key)">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col md="7" sm="12">
                    <v-select
                      v-model="item.id"
                      :items="moduleQuestions"
                      item-value="id"
                      item-text="title"
                      :rules="[v => !!v || 'Parent Question is required']"
                      label="Parent Question"
                      dense
                      required
                    >
                    </v-select>
                  </v-col>
                  <v-col md="5" sm="12">
                    <v-select
                      v-model="item.answer"
                      :items="answers"
                      :rules="[v => !!v || 'Parent Answer is required']"
                      label="Parent Answer"
                      dense
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <v-row class="mb-4 mt-3">
            <v-btn color="primary" @click="save">
              Save
            </v-btn>
            <v-btn outlined color="error" class="mx-2" to="/questions">
              Cancel
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable operator-linebreak */
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiDelete, mdiClose, mdiAccountOutline, mdiLink, mdiFormatText } from "@mdi/js";
import { mapState } from "vuex";
import question from "@/services/questionService";
import moodule from "@/services/moduleService";

export default {
  name: "QuestionCreateUpdate",
  data: () => ({
    formTitle: "Add Question",
    btnTitle: "Back To Questions",
    modules: [],
    moduleQuestions: [],
    temp: [],
    answers: ["yes", "no"],
    valid: true,
    id: null,
    form: {
      title: null,
      answer: [],
      moduleId: null,
      hasParent: false,
      hideAnswer: false,
      children: [],
      child: {},
      parent: [
        {
          id: null,
          answer: null,
        },
      ],
      link: null,
      linkText: null,
      initial: false,
      created: new Date(),
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiClose,
      mdiAccountOutline,
      mdiLink,
      mdiFormatText,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getModules();

      this.id = this.$route.params.id;
      if (this.id) {
        this.editQuestion(this.id);
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        try {
          if (this.form.moduleId == null || this.form.title == null) {
            this.$store.dispatch("notification/add", { type: "error", message: "Title and Module are required" });

            return;
          }
          if (this.form.link) {
            const check = this.isURL(this.form.link);
            if (check === false) {
              this.$store.dispatch("notification/add", { type: "error", message: "Enter a valid url" });

              return;
            }

            if (this.form.linkText == null) {
              this.$store.dispatch("notification/add", { type: "error", message: "Enter link text" });

              return;
            }
          }
          const newQuestion = {
            title: this.form.title,
            answer: this.answers,
            moduleId: this.form.moduleId,
            hasParent: this.form.hasParent,
            hideAnswer: this.form.hideAnswer,
            parent: this.form.hasParent ? this.form.parent : null,
            children: this.form.children,
            child: this.form.child ? this.form.child : {},
            link: this.form.link,
            linkText: this.form.linkText,
            initial: this.form.initial,
            created: this.form.created,
          };
          if (this.id) {
            if (this.form.children.length > 0) {
              this.form.children.forEach(item => {
                this.form.child[item.answer] = item.id;
              });
              newQuestion.child = this.form.child;
            }
            await question.updateQuestion(this.id, newQuestion);
          } else {
            let data = null;
            await moodule.getModule(this.form.moduleId).then(doc => {
              if (doc.exists) {
                data = doc.data();
                data.id = doc.id;
              }
            });

            if (data && data.questions === 0) newQuestion.initial = true;
            await question.addQuestion(newQuestion).then(async docRef => {
              const questions = { questions: data.questions ? data.questions + 1 : 1 };
              await moodule.updateModule(this.form.moduleId, questions);
              if (this.form.hasParent) {
                this.form.parent.forEach(async value => {
                  const newData = {
                    id: docRef.id,
                    answer: value.answer,
                  };
                  await question.updateChildrenArray(value.id, newData);
                });
              }
            });
          }

          this.$router.replace({ name: "questions" });
          this.$store.dispatch("notification/add", { type: "success", message: "Question saved" });
        } catch (error) {
          this.$store.dispatch("notification/add", { type: "error", message: error.message });
        }
      }
    },
    async getModules() {
      try {
        await moodule.getModules().then(querySnapshot => {
          const items = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const dataModules = {
              id: doc.id,
              name: data.name,
            };
            items.push(dataModules);
          });
          this.modules = items;
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
      }
    },
    getModuleQuestions() {
      try {
        question.getQuestionByField("moduleId", "==", this.form.moduleId).then(querySnapshot => {
          const items = [];
          querySnapshot.forEach(doc => {
            let data = {};
            if (this.form.id) {
              if (doc.id !== this.form.id) data = doc.data();
              else {
                return;
              }
            } else {
              data = doc.data();
            }
            const questions = {
              id: doc.id,
              title: data.title,
            };
            items.push(questions);
          });
          this.moduleQuestions = items;
          if (this.form.id && this.form.hasParent) this.form.hasParent = true;
          else {
            this.hasParent = false;
          }
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: error.message });
      }
    },
    addParent() {
      if (!this.form.hasParent) {
        this.form.hasParent = true;
      } else {
        this.form.parent.push({
          id: null,
          answer: null,
        });
      }
    },
    removeParent(index) {
      this.form.parent.splice(index, 1);

      if (index === 0) {
        this.form.parent.push({
          id: null,
          answer: null,
        });
        this.form.hasParent = false;
      }
    },
    async editQuestion(id) {
      await question.getQuestion(id).then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.form = data;
          this.form.id = doc.id;
          this.formTitle = "Edit Question";
          if (data.child) {
            this.form.child = data.child;
          } else {
            this.form.child = {};
          }
          this.getModuleQuestions();
          this.temp = this.form.parent;
          this.dialog = true;
        } else {
          this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
        }
      });
    },
    isURL(str) {
      const pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

      return !!pattern.test(str);
    },

    clearForm() {
      this.form.title = null;
      this.form.moduleId = null;
      this.form.children = [];
      this.form.child = {};
      this.form.parent = [
        {
          id: null,
          answer: null,
        },
      ];
      this.form.hideAnswer = false;
      this.form.hasParent = false;
      this.form.created = new Date();
      this.form.link = null;
      this.form.linkText = null;
      this.form.initial = false;
      this.form.parent = [
        {
          id: null,
          answer: null,
        },
      ];
      delete this.form.id;
      this.formTitle = "Add Question";
      this.temp = [];
    },
  },
};
</script>
